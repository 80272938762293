import { GridRow } from '@oresundsbron/api';
import { FC } from 'react';
import { Row } from './Row';

export const ContentfulGrid: FC<{ rows: GridRow[]; pageWrapper?: boolean }> = ({
  rows,
  pageWrapper,
}) => {
  return (
    <>
      {rows.map((row) => (
        <Row key={row.sys.id} {...row} pageWrapper={pageWrapper} />
      ))}
    </>
  );
};

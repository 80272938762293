import { CodeRef, Reference } from '@oresundsbron/api';
import { pipe } from 'fp-ts/lib/function';
import { getOrElseW } from 'fp-ts/lib/Option';
import { FC, useMemo } from 'react';
import { fromReference } from './utils';

export const codeRefExist = (code: Reference) => pipe(code, fromReference);

export const CodeReference: FC<Omit<CodeRef, 'ref'> & { code: Reference }> = ({
  code,
}) => {
  const content = useMemo(
    () =>
      pipe(
        code,
        fromReference,
        getOrElseW(() => null)
      ),
    [code]
  );

  return <>{content}</>;
};

import { Metadata } from '@oresundsbron/api';
import { useMemo } from 'react';
import { MetadataItem, mergeMetadata, toMetadata } from '../lib/metadata';

export function useSEOMetadata(
  metadata?: Metadata[],
  base: MetadataItem[] = []
) {
  return useMemo(() => {
    return mergeMetadata(base, toMetadata(metadata || []));
  }, [metadata, base]);
}

import { Reference } from '@oresundsbron/api';
import { flow, pipe } from 'fp-ts/lib/function';
import { alt, fromPredicate, map } from 'fp-ts/lib/Option';
import dynamic from 'next/dynamic';
import { cloneElement, ComponentType, ReactElement } from 'react';

const render = map<ComponentType, ReactElement>((Comp) => <Comp />);
const resolve = (comp: ComponentType) => map(() => comp);

const privateSignUp = dynamic(() =>
  import('../signup/private').then((mod) => mod.SignUp)
);
const businessSignUp = dynamic(() =>
  import('../signup/business').then((mod) => mod.SignUp)
);
const brobizzSignUp = dynamic(() =>
  import('../signup/brobizz').then((mod) => mod.SignUp)
);
const skyttelpassSignUp = dynamic(() =>
  import('../signup/skyttelpass').then((mod) => mod.SignUp)
);
const itick = dynamic(() =>
  import('../iTicket').then((mod) => mod.OnlineTicket)
);
const contactForm = dynamic(() =>
  import('../ContactForm').then((mod) => mod.ContactForm)
);

const club = dynamic(() => import('../Club').then((mod) => mod.Club));
const faq = dynamic(() => import('../FAQ').then((mod) => mod.FAQ));
const periodCardRegretForm = dynamic(() =>
  import('../PeriodCardRegretForm').then((mod) => mod.PeriodCardRegret)
);

const isPrivateSignUp = flow(
  fromPredicate<Reference>((x) => x === 'private_sign_up'),
  resolve(privateSignUp),
  render
);

const isPrivateCommuterSignUp = flow(
  fromPredicate<Reference>((x) => x === 'private_commuter_sign_up'),
  resolve(privateSignUp),
  render,
  map((comp) => cloneElement(comp, { commuter: true }))
);

const isBusinessSignUp = flow(
  fromPredicate<Reference>((x) => x === 'business_sign_up'),
  resolve(businessSignUp),
  render
);

const isBusinessCommuterSignUp = flow(
  fromPredicate<Reference>((x) => x === 'business_commuter_sign_up'),
  resolve(businessSignUp),
  render,
  map((comp) => cloneElement(comp, { commuter: true }))
);

const isBroBizzSignup = flow(
  fromPredicate<Reference>((x) => x === 'brobizz_sign_up'),
  resolve(brobizzSignUp),
  render
);

const isBroBizzBusinessSignup = flow(
  fromPredicate<Reference>((x) => x === 'brobizz_business_sign_up'),
  resolve(brobizzSignUp),
  render,
  map((comp) => cloneElement(comp, { business: true }))
);

const isSkyttelpassSignup = flow(
  fromPredicate<Reference>((x) => x === 'skyttelpass_sign_up'),
  resolve(skyttelpassSignUp),
  render
);

const isSkyttelpassBusinessSignup = flow(
  fromPredicate<Reference>((x) => x === 'skyttelpass_business_sign_up'),
  resolve(skyttelpassSignUp),
  render,
  map((comp) => cloneElement(comp, { business: true }))
);

const isITicket = flow(
  fromPredicate<Reference>((x) => x === 'iticket'),
  resolve(itick),
  render
);

const isContactForm = flow(
  fromPredicate<Reference>((x) => x === 'contact_form'),
  resolve(contactForm),
  render
);

const isClub = flow(
  fromPredicate<Reference>((x) => x === 'club'),
  resolve(club),
  render
);

const isFAQ = flow(
  fromPredicate<Reference>((x) => x === 'faq'),
  resolve(faq),
  render
);

const isPeriodCardRegretForm = flow(
  fromPredicate<Reference>((x) => x === 'period_card_regret_form'),
  resolve(periodCardRegretForm),
  render
);

export const fromReference = (ref: Reference) =>
  pipe(
    ref,
    isPrivateSignUp,
    alt(() => pipe(ref, isPrivateCommuterSignUp)),
    alt(() => pipe(ref, isBusinessSignUp)),
    alt(() => pipe(ref, isBusinessCommuterSignUp)),
    alt(() => pipe(ref, isBroBizzSignup)),
    alt(() => pipe(ref, isBroBizzBusinessSignup)),
    alt(() => pipe(ref, isSkyttelpassSignup)),
    alt(() => pipe(ref, isSkyttelpassBusinessSignup)),
    alt(() => pipe(ref, isITicket)),
    alt(() => pipe(ref, isContactForm)),
    alt(() => pipe(ref, isClub)),
    alt(() => pipe(ref, isFAQ)),
    alt(() => pipe(ref, isPeriodCardRegretForm))
  );
